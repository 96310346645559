.show-xs {
  display: none;
}

.hide-xs {
  display: block;
}

.hide-xxs {
  display: block;
}

.hide-xs {
  display: block;
}


@media screen and (max-width: 600px) {
  .MuiButtonBase-root > label {
    display: none;
  }
  .hide-xs {
    display: none;
  }
  .show-xs {
    display: block;
  }
} 

@media screen and (max-width: 330px) {
  .hide-xxs {
    display: none;
  }
} 


.MuiButtonBase-root > label {
  margin-left: 0.2rem;
}

.MuiToggleButton-root > * {
  opacity: 0.6;
}

.MuiToggleButton-root.Mui-selected > * {
  opacity: 1;
}

