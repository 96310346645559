body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.vehicle-Red,
.vehicle-Blue,
.vehicle-Green,
.vehicle-Orange,
.vehicle-Red,
.vehicle-CR,
.vehicle {
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  z-index: 1;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  font-size: 9px;
  overflow: show;
  transform: translate(-50%, -50%);
  transform-origin: center;
  &:hover {    
    z-index: 2; 
    transform: scale(1.1);
    background-color: white;
  } 
}

.vehicle,.vehicle  { 
  /* color: gold;  */
  /* text-shadow: 0 0 4px goldenrod;  */
}

.vehicle-Red,.vehicle-R  { 
  /* color: red;  */
  /* text-shadow: 0 0 4px darkred;  */
}
.vehicle-Blue,.vehicle-B  { 
  /* color: blue;  */
  /* text-shadow: 0 0 4px darkblue; */
  
}
.vehicle-Green  {
  /* color: green; */
  /* text-shadow: 0 0 4px darkgreen; */
}
.vehicle-Orange,.vehicle-O  { 
  /* color: orange;  */
  /* text-shadow: 0 0 4px darkorange; */

}
.vehicle-CR { 
  /* font-size: 20px; */
  /* color: purple;  */
  /* text-shadow: 0 0 4px purple; */
}


.dot {
  width: 4px;
  height: 8px;
  border-radius: 2px;
  background-color: darkgoldenrod;
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  transform: translate(-50%, -50%);
  &:hover {    
    z-index: 1;  
  } 
}

.IN_TRANSIT_TO,
.INCOMING_AT {
  width: 6px;
  height: 6px;
  /* aspect-ratio: 1; */
  border-radius: 4px;
  background-color: darkgray;
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  filter: saturate(0.55) blur(2px);
  z-index: 2;
  &:hover { 
    z-index: 5; 
  } 
}

.STOPPED_AT {
  width: 12px;
  aspect-ratio: 1;
  border-radius: 6px;
  background-color: darkgray;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  user-select: none;
  filter: saturate(0.5) blur(2px);
  &:hover { 
    z-index: 4; 
  } 
}

.fab-cluster { 
  z-index: 902;
  position: absolute;
  right: '5px';
  bottom: '15px';
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fab-cluster > button {
  margin: 3px 5px;
}

.fill-space {
  display: flex;
  flex: 1 1 auto;
}
 

.gm-bundled-control.gm-bundled-control.gm-bundled-control {
  opacity: 0.7;
  top: 5px;
}
/*
.gm-style-iw,.gm-style-iw-t {
  display: none;
} */

